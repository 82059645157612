
export const clearLocalStorageData = () =>{
    localStorage.removeItem('UserDetails');
}
export const formatNumberWithComma = (numb) => {
    let str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
}

export const convertToUpperCase = (Name) =>{
    return Name.toUpperCase();
}


export const getCategoryName=(ID,Data)=>{
    let Name = ' ';
    // console.log("Contr"+JSON.stringify(Data))
    let Id = parseInt(ID) ;
    Data.map((item,index)=>{if (item.id === Id){Name = item.name;}})
    return Name.toUpperCase();
    // const categoryName = data.find(item => item.name === "Category Category")?.name;
}
