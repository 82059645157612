

import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import { AUTH_TOKEN, APIOrdersList, APIProductFileName, } from '../../Utilities/APIS';
import { formatNumberWithComma } from '../../Utilities/Functions';

export default class Orders extends React.Component {
    
constructor(props){
    
    super(props);
    this.state = {
        OrdersData:[],
        OrderListData:[],

        ItemShowCustomer:'',
        ItemShowLocation:'',
        ItemShowPrice:'',
        ItemShowOrderDate:'',
        ItemShowOrderNumber:'',
    }    
}

componentDidMount(){
    this.loadClients();
}

loadClients  = () =>{
    const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

    axios.get(APIOrdersList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({OrdersData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}

handleCloseDetailsModel = () => {this.setState({ShowDetailsModel:false});}
handleShowDetailsModel = (customer,location,price,orderDate,orderNumber,orderList) => {
    this.setState({ItemShowCustomer:customer});
    this.setState({ItemShowPrice:price});
    this.setState({ItemShowLocation:location});
    this.setState({ItemShowOrderDate:orderDate});
    this.setState({ItemShowOrderNumber:orderNumber});
    this.setState({OrderListData: JSON.parse(orderList)});
    this.setState({ShowDetailsModel:true});
}

render() {
    const {showAlert,OrdersData,ShowDetailsModel} =this.state;
return (
            <div  className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>

                        <div className="alert alert-light bg-light text-light border-0 alert-dismissible" role="alert">
                            <strong className='strong-text primary-text' >Clients Listings</strong><span className='strong-text primary-text'>View</span>
                            <div className="custom-alert-btn-actions">
                                {/* <ButtonGroup className="mb-2">
                                    <Button variant="primary" onClick={()=>this.handleShow()}>
                                        Add New User
                                    </Button>
                                </ButtonGroup> */}
                                </div>
                        </div><br/>
                        {this.state.ShowAxiosErrorAlert ?(<>
                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                <p>{this.state.AllInputsRequiredError}</p>
                                <p>{this.state.AxiosErrorMessage}</p>
                                <p>{this.state.AxiosErrorResponse}</p>
                            </div>
                        </>):(<></>)}
                        <div className="row">
                            {showAlert ?(<> 
                                    <Alert variant="success" onClose={() => this.setState({showAlert: false})} dismissible>
                                        <Alert.Heading>Success </Alert.Heading>
                                        <p>{this.state.postMsg}</p>
                                    </Alert>
                                </>) :(<> </>)}
                            <div className="col-12">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                    <table className="table table-hover"  id="table">
                                    <thead>
                                        <tr>
                                            <th className="tr-td-labels text-center"> SR.No</th>
                                            <th className="tr-td-labels text-center">Date</th>
                                            <th className="tr-td-labels text-center">Client</th>
                                            <th className="tr-td-labels text-center">Order Number</th>
                                            <th className="tr-td-labels text-center">Price</th>
                                            <th className="tr-td-labels text-center">Status</th>
                                            <th className="tr-td-labels text-center">Details</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                        {OrdersData && OrdersData.map((item,index)=>(
                                            <tr key={index}>
                                            <td className="tr-td-labels text-center">{index+1}</td>
                                            <td className="tr-td-labels text-center">{item.orderDate}</td>
                                            <td className="tr-td-labels text-center">{item.customer}</td>
                                            <td className="tr-td-labels text-center">{item.orderNumber}</td>
                                            <td className="tr-td-labels text-center">{ formatNumberWithComma(item.price)}</td>
                                            <td className="tr-td-labels text-center">
                                            <span class="badge rounded-pill bg-danger">{item.status}</span>
                                                </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="dark" onClick={()=>this.handleShowDetailsModel(item.customer,item.itemName,item.location,item.price,
                                                    item.orderDate,item.orderList
                                                )}>
                                                    Show Item
                                                </Button>
                                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Details Model   */}
                        <Modal size='lg' show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                            <Modal.Header>
                            <Modal.Title>Order Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='my-grid-container-3-columns' >
                                    <div className='my-grid-item'>
                                        <div>
                                        <p className="primary-text" >
                                            <b>Customer</b><br></br>
                                            {this.state.ItemShowCustomer}
                                            </p>
                                        </div>
                                    </div>

                                    <div className='my-grid-item'>
                                        <div>
                                            <p className="primary-text" >
                                            <b>Location</b><br></br>
                                            {this.state.ItemShowLocation}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='my-grid-item'>
                                        <div>
                                        <p className="primary-text" >
                                        <b>Price</b><br></br>
                                        {this.state.ItemShowPrice}
                                        </p>
                                        </div>
                                    </div>

                                    <div className='my-grid-item'>
                                        <div>
                                        <p className="primary-text" >
                                            <b>Order Date</b><br></br>
                                            {this.state.ItemShowOrderDate}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='my-grid-item'>
                                        <div>
                                        <p className="primary-text" >
                                        <b>Order Number</b><br></br>
                                        {this.state.ItemShowOrderNumber}
                                        </p>
                                        </div>
                                    </div>
                                </div>

                                {this.state.OrderListData && this.state.OrderListData.map((item,index)=>(
                                    <div key={index}>
                                        <div class="alert border-primary alert-dismissible fade show" role="alert">
                                            <div className='my-grid-container-3-columns' >
                                                <div className='my-grid-item'>
                                                    <p className="primary-text" >
                                                        <b>Product</b><br></br>
                                                        <img src={APIProductFileName+item.fileName} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                                        </p>
                                                </div>
                                                <div className='my-grid-item'>
                                                    <p className="primary-text" >
                                                        <b>Quantity</b><br></br>
                                                        {item.qty}
                                                        </p>
                                                </div>
                                                <div className='my-grid-item'>
                                                    <p className="primary-text" >
                                                        <b>Price</b><br></br>
                                                        {formatNumberWithComma(item.price)}
                                                        </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>
                        
                    </div>
                    {/* End col */}
            </div>
);
}}
