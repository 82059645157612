

import React,{Component} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import { APIUserListByEmail, AUTH_TOKEN } from '../Utilities/APIS';
import { INPUTS_REQUIRED } from '../Utilities/Errors';


class LogIn extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        isAuthenticated:false,
        UserEmail:'',
        UserPassword:'',
        NoRecordsFound:'',
        CredentialMissMatch:'',
        UserResearch:[],

        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',


    }    
}

componentDidMount(){

}

setEmailTextInput = (text) =>{this.setState({UserEmail:text.target.value})}
setPasswordTextInput = (text) =>{this.setState({UserPassword:text.target.value})}



goToDashboardScreen = (userId,userName) =>{
    let dashboardProps = {
        userId:userId,
        userName:userName,
    };
    this.props.navigate("/dashboard",{ state: dashboardProps });
}

logInUser = async () =>{
    const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

    let email = this.state.UserEmail;
    let password = this.state.UserPassword

    if (email.length == 0 || password.length == 0){
        this.setState({AllInputsRequiredError:INPUTS_REQUIRED});
        this.setState({ShowAxiosErrorAlert:true});
    }
    else
    {
        try{
            const postRequest = await axios.get(APIUserListByEmail+email,{headers})
        
            let results = postRequest.data;
            let jsonString = JSON.stringify(results)
            let jsonResults =JSON.parse(jsonString);

            if (results.length === 0)
            {let msg = "Sorry !! No Records  Found "; this.setState({NoRecordsFound:msg})}
            else
            {
                let DbEmail = jsonResults.email;
                let DbPassword  = jsonResults.password;
                if ((email === DbEmail)&&(password === DbPassword ))
                    {
                        this.setState({isAuthenticated:true});
                        let userName= jsonResults.fullName;
                        let userEmail = jsonResults.email;
                        let userId = jsonResults.id;
                        let profileImage = jsonResults.profileImage;

                        let Details = {name:userName,profileImage:profileImage,email:userEmail,id:userId,isAuthenticated:"YES"};
                        localStorage.setItem('UserDetails',JSON.stringify(Details));
                        this.props.navigate("/dashboard");
                    }
                else
                {let msg = "Sorry !!  Invalid Credentials Email Or Password Try Again"; this.setState({CredentialMissMatch:msg})}
            }
        }
        catch (error){
            console.log(error)
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        }
    }
}
        

render() {
const {UserEmail,UserPassword,NoRecordsFound,CredentialMissMatch} =this.state;

return (
        <main className="main-content  mt-0">
            <div className="page-header align-items-start min-vh-100">
                <span className="mask bg-gradient-dark opacity-6"></span>
                    <div className="container my-auto">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 col-12 mx-auto">
                                <br/><br/><br/><br/>
                                <div className="card z-index-0 fadeIn3 fadeInBottom">
                                    <div className="card-body">
                                        <center>
                                            {/* <br/>
                                            <img src={Logo} alt='logo-icon' className="logo-icon" /> */}
                                            <br/>
                                            <h4><b>Welcome Back!</b></h4>
                                            <h5 className='log-red-color' ><b>Bronia Admin</b></h5>
                                            <h6><b>Log in to Continue</b></h6>
                                        </center>
                                        {this.state.ShowAxiosErrorAlert ?(<>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                <p>{this.state.AllInputsRequiredError}</p>
                                                <p>{this.state.AxiosErrorMessage}</p>
                                                <p>{this.state.AxiosErrorResponse}</p>
                                            </div>
                                        </>):(<></>)}
                                        <div>
                                            <p className="primary-text" >Email</p>
                                            <input name="email" className="form-control form-inputs" autoComplete="off" placeholder="User Email"
                                                type="text"
                                                value={UserEmail}
                                                onChange={text => this.setEmailTextInput(text)}
                                            />
                                        </div><br/>

                                        <div>
                                            <p className="primary-text" >Password</p>
                                            <input  className="form-control form-inputs" autoComplete="off" placeholder="User Password"
                                                type="password"
                                                value={UserPassword}
                                                onChange={text => this.setPasswordTextInput(text)}
                                            />
                                        </div><br/>
                                        <div>
                                            <p className="text-danger" >{NoRecordsFound}</p>
                                            <p className="text-danger" >{CredentialMissMatch}</p>
                                        </div>
                                        {/* <Link to="/register">
                                            <label className="form-check-label mb-0 ms-3" htmlFor="rememberMe"> 
                                            Don’t have account ? </label><span><b><u>Register Now</u></b></span><br/>
                                            </Link> */}
                                        {/* <label className="form-check-label mb-0 ms-3" htmlFor="rememberMe">Forgot Password  ! </label><span><b><u>Click Here</u></b></span><br/> */}

                                        <br/>
                                        <div className="text-center">
                                        <button onClick={()=>this.logInUser()} className="btn btn-primary custom-bg-primary custom-text-white" style={{width:"80%"}}> Log in </button>

                                        {/* <br/><br/>
                                        <center><p><b>-- OR --</b></p></center>
                                        <button onClick={()=>this.guestLogIn()} className="btn btn-success custom-bg-primary custom-text-white" style={{width:"80%"}}> Log In  As A Guest</button> */}
                                        </div><br/>

                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </main>
);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}



// Wrap the class component with the functional component to get access to navigate
export default withNavigation(LogIn);